
  
  /* Container */
  .profile-container {
    max-width: 1400px;
    margin: 20px auto;
    /* padding: 20px; */
    background-color: #f7f7f7;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    padding-bottom: 1rem;
  }
  
  /* Header background */
  .profile-header {
    position: relative;
    height: 200px;
    background: url('./img/background.png') no-repeat center center;
    background-size: cover;
    border-radius: 10px 10px 0 0;
  }
  
  /* Profile image */
  .profile-image {
    position: absolute;
    top: 100%;
    left: 5%;
    transform: translateY(-50%);
    width: 200px;
    height: 200px;
    border-radius: 50%;
    border: 5px solid #f7f7f7;
    overflow: hidden;
  }
  
  /* Profile info */
  .profile-info {
    padding-left: 21%;
  }
  
  .profile-info h2 {
    font-size: 24px;
    margin: 10px 0;
  }
  
  .profile-info p {
    color: #666;
    font-size: 16px;
  }
  
  /* Contact and Edit button */
  .contact-info {
    display: flex;
    /* justify-content: center;
    align-items: center; */
    margin: 10px 0;
  }
  
  .contact-info i {
    margin-right: 5px;
  }
  
  .edit-button {
    margin-top: 10px;
    background-color: #3498db;
    color: #fff;
    border: none;
    padding: 8px 15px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  /* Section */
  .profile-section {
    margin: 20px 80px;
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #fff;
  }
  
  .profile-section h3 {
    font-size: 20px;
    margin-bottom: 10px;
  }
  
  .profile-section p {
    color: #666;
    font-size: 14px;
  }
  
  



/* Portfolio Section */
.portfolio-container {
  display: flex;
  gap: 20px;
  margin: 20px 80px;
  background-color: #fff;
  padding: 15px;
  border-radius: 8px;
  border: 1px solid #ddd;
}

.portfolio-category {
  flex: 1;
}

.portfolio-category h3 {
  font-size: 18px;
  margin-bottom: 10px;
}

.portfolio-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}

.portfolio-item {
  position: relative;
  overflow: hidden;
  border-radius: 5px;
}

.portfolio-item img {
  width: 100%;
  display: block;
  border-radius: 5px;
}

.portfolio-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.6);
  color: #fff;
  padding: 5px;
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.portfolio-overlay p {
  margin: 0;
}

.portfolio-overlay span {
  font-size: 12px;
}

/* Responsive */
@media (max-width: 768px) {
  .profile-info {
    padding-left: 10%;
  }

  .portfolio-grid {
    grid-template-columns: 1fr;
  }
}
/* Responsive */
@media (max-width: 768px) {
  .profile-header {
    height: 150px;
  }
  .profile-info h2 {
    font-size: 20px;
  }
  .profile-info p {
    font-size: 14px;
  }
  .profile-section {
    padding: 10px;
  }
}
@media (max-width:520px) {
  .profile-image{
   
    width: 120px;
    height: 120px;
    
  }
  .profile-info{
    padding-left: 40%;
  }
  .edit-button{
    display: none;
    width: 50%;
  }
  .profile-section{
    margin: 20px 20px;
  }
  .portfolio-container{
    margin: 20px 20px;
  }
  .portfolio-grid{
    display: flex;
  }
  .portfolio-container{
    display: block;
  }
  .portfolio-category{
    margin-bottom: 10px;
  }
}