.create-input {
    margin-top: 0px;
}

.create-backgorund {
    /* background-color: #f4f4f4; */
    padding-top: 40px;
    padding-bottom: 50px;
    
}

.create-container {
    display: flex;
    /* justify-content: center; */
    /* align-items: center; */
    flex-direction: column;
    /* height: 80vh; */
    width: 80%;
    max-width: 1200px;
    margin: 0px auto;
    /* background-image: url('./img/Mask-group.png'); */
    background-size: cover;         /* Ensures the image covers the full container */
    background-position: center;    /* Centers the background image */
    background-repeat: no-repeat;   /* Prevents image repetition */
    padding-top: 80px;
    padding-bottom: 80px;
    border-radius: 10px;
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
}

.create-heading {
    font-size: 24px;
    margin-bottom: 20px;
    color: #333;
}

.create-form {
    display: flex;
    flex-direction: column;
}

.form-row {
    display: flex;
    justify-content: start;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 15px;
    width:80vw
}

.form-group {
    width: 32%;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.form-group label {
    font-size: 14px;
    color: #333;
    margin-bottom: 5px;
}

.form-group input, .form-group textarea {
    padding: 10px;
    border: 2px solid #ffffff;
    border-radius: 5px;
    font-size: 14px;
    color: #333;
    background-color: #e6f0ff;
}

.form-group textarea {
    height: 40px;
}

.createTenderButton {
    background-color: #5a99f6;
    color: white;
    padding: 12px 20px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.createTenderButton:hover {
    background-color: #4a88d1;
}

.button-container {
    /* text-align: right; */
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .form-row {
        flex-direction: column;
    }
    
    .form-group {
        width: 100%;
        margin-bottom: 15px;
    }
    
    .button-container {
        text-align: center;
    }
}

@media (max-width: 480px) {
    .create-heading {
        font-size: 20px;
    }

    button {
        width: 100%;
        padding: 10px;
        font-size: 14px;
    }
    .create-container{
        padding-top: 30px;
    }
}
