.container {
    width: 100%;
    display: flex;
    /* justify-content: space-between; */
    justify-content: center;
    align-items: center;
  }
  
  .detailed_service {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 20px; /* space between services */
  }
  
  .flex50 {
    width: 50%;
  }
  
  @media (max-width: 768px) {
    .container {
      flex-direction: column;
    }
    .flex50 {
      width: 88%;
    }
  }
  
.detailed_service{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px 80px;
    background-color: #ccc;
}

.arrow_detailServices{
    position: relative;
    left: 150px;
    zoom: 2;
}

@media screen and (max-width: 480px){
    .arrow_detailServices {
        display: none;
    }
}
@media screen and (max-width: 440px){
    .detailed_service{
        padding: 20px 20px;
    }
}
/* Add this to your CSS module */
.midBorder {
  border-right: 1px solid #ccc; /* Adjust the color and width as needed */
  padding-right: 20px; /* Optional: Adds spacing to the right */
  margin-right: 10px; /* Optional: Adds space between the border and content */
}

.mb{
  margin-bottom: 3rem;
}