/* .search-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    max-width: 1050px;
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    margin-top: 2rem;
    margin-bottom: 2rem;
    flex-wrap: wrap; 
}

.left-text p {
    margin-right: 20px;
    font-size: 16px;
}

.results {
    font-weight: bold;
    color: #333;
}

.right-search {
    display: flex;
    align-items: center;
    position: relative;
}

.right-search input {
    width: 490px;
    padding: 10px 40px 10px 20px;
    border-radius: 10px;
    border: 1px solid #ddd;
    outline: none;
    font-size: 14px;
}

.right-search button {
    position: absolute;
    right: 10px;
    background-color: transparent;
    border: none;
    font-size: 18px;
    cursor: pointer;
}


@media (max-width: 768px) {
    .search-container {
        width: 90%; 
        padding: 15px;
    }

    .left-text p {
        font-size: 14px;
        margin-right: 15px;
    }

    .right-search input {
        width: 250px; 
        padding: 8px 40px 8px 15px;
    }

    .right-search button {
        font-size: 16px; 
    }
}

@media (max-width: 480px) {
    .search-container {
        width: 95%; 
        padding: 10px;
        flex-direction: column; 
        align-items: flex-start; 
    }

    .left-text p {
        font-size: 12px; 
        margin-bottom: 10px; 
    }

    .right-search input {
        width: 100%; 
        padding: 8px 40px 8px 15px;
    }

    .right-search button {
        font-size: 16px; 
    }
    .search-container {
       
        width: 95%;
       
        margin-top: 20px;
        margin-bottom: 20px;
      
    }
}

 */




/* Search Bar Styling */
.search-bar {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    padding: 15px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    align-items: center;
  }
  
  .search-dropdown select {
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 14px;
    outline: none;
    width: 100%;
  }
  
  .search-input {
    flex-grow: 1;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 14px;
    outline: none;
    min-width: 150px;
  }
  
  /* Categories Styling */
  .categories-container {
    margin: 15px 2rem;
  }
  
  .searching-categories {
    display: flex;
    gap: 15px;
    padding: 15px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-right: 5rem;
    margin-left: 5rem;
  }
  
  .searching-category {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    text-align: center;
    min-width: 15.5%;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .searching-category img {
    width: 24px;
    height: 24px;
  }
  
  .searching-category span {
    font-size: 17px;
    color: #555;
  }
  
  /* .searching-category.active {
    border-color: #007bff;
    color: #007bff;
  } */
  
  .searching-category:hover {
    background-color: #f0f0f0;
    border-color: #bbb;
  }
  
  .mobile-category-dropdown {
    display: none;
    margin-top: 15px;
  }
  
  .mobile-category-dropdown select {
    padding: 10px;
    width: 100%;
    font-size: 14px;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  .search-container{
    width: 90%;
  }
  .search-dropdown{
    width: 50%;
  }
  .mt-15{
margin-top: 15px;
  }
  
  /* Responsive Styling */
  @media (max-width: 768px) {
    .searching-categories {
      display: none; /* Hide categories row on mobile */
    }
  
    .mobile-category-dropdown {
      display: block; /* Show dropdown on mobile */
    }
  }

