.signup-button.active, .login.active {
  background-color: #91aeee;
  color: white;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.d-flex {
  display: flex;
}

.width33 {
  width: 33%;
}

.width331 {
  width: 33%;
}

.margin1rembottom {
  margin-bottom: 1rem;
}

.width100 {
  padding-top: 10px;
  box-shadow: 0px 2px 6px 0px #0000001A;
  padding-bottom: 10px;
}

.ul {
  text-decoration: none;
  list-style: none;
}

.mx-3 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.text-center {
  text-align: center;
}

.align-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.logo1 {
  width: 200px;
  height: 47px;
}

.menu {
  text-decoration: none;
  font-family: Poppins;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  text-align: left;
  color: black;
}

.button1 {
  border: 2px solid #91aeee;
  border-radius: 2rem;
  padding: 3px;
}

.signup-button {
  padding: 6px 20px;
  gap: 10px;
  border-radius: 50px;
  border: none;
  background-color: white;
  color: #91aeee;
  font-size: 20px;
}

.login {
  padding: 6px 20px;
  gap: 10px;
  border-radius: 50px;
  border: none;
  background-color: white;
  color: #91aeee;
  font-size: 20px;
}

.bars {
  margin-left: 20px;
  display: none;
}

.smallnav {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: fixed;
  top: 70px; /* Position at the top */
  background-color: white;
  width: 100vw;
  padding: 20px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  transform: translateY(-120%);
  transition: all 0.5s ease;
  z-index: 1000;
}

.navvisible {
  transform: translateY(0); /* Slide down from top */
}

.navhide {
  transform: translateY(-180%);
}

@media (max-width: 990px) {
  .navbarnone {
      display: none;
  }
}

@media (max-width: 998px) {
  .bars {
      display: block;
  }
}

@media (max-width: 998px) {
  .justify-around {
      justify-content: space-around !important;
  }
  .width33 {
      width: 100%;
  }
}

/* Dropdown Styles */
.dropdown {
  position: relative;
}

.dropdown-toggle {
  cursor: pointer;
  justify-content: center;
  display: flex;
  align-items: center;
  gap: 6px;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 10px 20px;
  z-index: 1000;
  width: 200%;
}

.dropdown-item {
  text-decoration: none;
  color: black;
  padding: 5px 0;
  display: block;
}

.dropdown-item:hover {
  color: #91aeee;
}

.logout-button {
  background-color: #5a99f6;
  color: white;
  padding: 6px 20px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.profile-button{
  margin: 0 10px;
  background-color: transparent;
  color: #5a99f6;
  padding: 6px 20px;
  font-size: 16px;
  border: 1px solid #5a99f6;
  border-radius: 5px;
  cursor: pointer;
}
.profile-button:hover{
  background-color: #5a99f6;
  color: white;
}