/* Default desktop styles (unchanged) */
.signup {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 80px;
    flex-wrap: wrap;
}

.leftsignup {
    width: 45%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.leftsignup img {
    width: 80%;
}

.rightSignup {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
    width: 45%;
}

.signUpTitle {
    font-size: 50px;
    font-weight: 500;
}

.rightSignup form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
}
.inputdiv{

}

.inputdiv input {
    border-left: none;
    border-right: none;
    border-top: none;
    border-bottom: 1px solid #d1d1d1;
    /* border: none; */
    width: 80%;
    padding: 10px 14px;
    opacity: 0.8;
}

.inputdiv input::placeholder {
    color: black;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 500;
    line-height: 22.5px;
    text-align: left;
}

.submitButtonForSignup {
    width: fit-content;
    padding: 10px 48px;
    gap: 0;
    border-radius: 10px;
    background-color: #91aeee;
    opacity: 0.8;
    color: white;
    border: none;
    font-size: 16px;
    letter-spacing: 2px;
}

.bootomsign {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 80%;
    gap: 10px;
}

.bootomlineSignup {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
}

.bootomlineSignup span {
    color: #0075E8;
    text-align: center;
}

.loginbutton {
    text-decoration: none;
}

.inputdiv {
    display: flex;
    align-items: center;
}

.inputicon {
    color: #91aeee;
    font-size: 25px;
    margin: 0 5px;
}

/* Responsive styles */

/* For tablets */
@media (max-width: 992px) {
    .signup {
        flex-direction: column;
        align-items: center;
        gap: 40px;
    }

    .leftsignup,
    .rightSignup {
        width: 80%;
    }

    .signUpTitle {
        font-size: 40px;
    }

    .inputdiv input {
        width: 100%;
    }

    .bootomsign {
        width: 100%;
    }
}

/* For mobile devices */
@media (max-width: 576px) {
    .signup {
        flex-direction: column;
        align-items: center;
        gap: 20px;
        padding: 0 20px;
    }

    .leftsignup,
    .rightSignup {
        width: 100%;
    }

    .leftsignup img {
        width: 90%;
       
    }

    .signUpTitle {
        font-size: 30px;
        text-align: center;
    }

    .inputdiv input {
        width: 100%;
        padding: 8px 12px;
        font-size: 16px;
    }

    .submitButtonForSignup {
        padding: 8px 24px;
        font-size: 14px;
    }

    .bootomsign {
        width: 100%;
    }

    .bootomlineSignup {
        font-size: 14px;
    }
}
.eye-icon {
    cursor: pointer;
    margin-left: -30px; /* Adjust based on your design */
    margin-top: 13px; /* Adjust based on your design */
    /* color: #aaa; */
    font-size: 1.2em;
    z-index: 2;
  }
  