/* .footerSection{
  border-top:1px solid rgb(209, 206, 206);
  padding: 10px;
}



.footer-width-25 {
  width: 25%;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
} */

/* Text and logo adjustments */
/* .footertext {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  width: 280px;
  margin-bottom: 20px;
}

.footerlogo {
  width: 80px;
  height: 78px;
  margin-bottom: 20px;
}

.footercompany {
  font-family: Poppins;
  font-size: 22px;
  font-weight: 500;
  line-height: 33px;
  margin-bottom: 20px;
}

.footerlink {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
  text-decoration: none;
  color: black;
  padding-bottom: 20px;
} */

/* Media Queries for responsiveness */

/* Tablet view */
/* @media (max-width: 1024px) {
  .footer-width-25 {
      width: 50%; 
      margin-bottom: 30px;
  }

  .footertext, .footerlink, .footercompany {
      text-align: center;
  }

  .footerlogo {
      margin-bottom: 30px;
  }
} */

/* Mobile view */
/* @media (max-width: 768px) {
  .footer-width-25 {
      width: 100%; 
      margin-bottom: 40px;
  }

  .main.d-flex {
      flex-direction: column;
      align-items: center;
  }

  .footertext, .footerlink, .footercompany {
      text-align: center;
  }
  
  .footerlogo {
      margin-bottom: 40px;
  }
} */







* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: Arial, sans-serif;
}

.footer-container {
  width: 100%;
  background-color: #f9f9f9;
  padding: 20px;
  text-align: center;
  border-top: 1px solid #e0e0e0;
}

.footer-logo img {
  width: 50px;
}

.footer-social-icons {
  display: flex;
  justify-content: center;
  margin: 20px 0;
  gap: 10px; /* Add gap between icons */
}

.footer-social-icons a {
  color: #333;
  font-size: 20px;
  text-decoration: none;
}

.footer-social-icons a:hover {
  color: #007bff;
}

.footer-navigation {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin: 20px 0;
}

.footer-navigation a {
  color: #333;
  text-decoration: none;
  font-weight: bold;
  font-size: 20px;
}

.footer-navigation span {
  color: #ccc;
}

.footer-description {
  max-width: 600px;
  margin: 10px auto;
  color: #555;
  font-size: 14px;
  line-height: 1.6;
}

.footer-credit {
  color: #333;
  font-size: 12px;
  margin-top: 20px;
}

/* Tablet Screen (768px and below) */
@media (max-width: 768px) {
  .footer-container {
    padding: 20px;
  }

  .footer-logo img {
    width: 50px;
  }

  .footer-social-icons a {
    font-size: 18px;
  }

  .footer-navigation {
    flex-wrap: wrap;
    gap: 10px;
  }

  .footer-description {
    font-size: 13px;
  }
}

/* Mobile Screen (480px and below) */
@media (max-width: 480px) {
  .footer-container {
    padding: 15px;
  }

  /* Make the logo larger on mobile */
  .footer-logo img {
    width: 55px;
  }

  /* Display social icons side by side with larger size */
  .footer-social-icons {
    display: flex;
    justify-content: center;
    gap: 15px;
  }

  .footer-social-icons a {
    font-size: 24px; /* Larger icon size */
  }

  /* Display navigation menu in a single row */
  .footer-navigation {
    display: flex;
    justify-content: center;
    gap: 15px;
    flex-wrap: nowrap; /* Prevent wrapping on smaller screens */
    margin: 15px 0;
  }

  /* Adjust navigation links to fit better */
  .footer-navigation a {
    font-size: 18px; /* Slightly larger text size */
  }

  .footer-navigation span {
    display: none; /* Hide separators for better readability on small screens */
  }

  /* Adjust description font size and padding */
  .footer-description {
    font-size: 14px;
    padding: 0 10px;
  }

  /* Footer credit section adjustment */
  .footer-credit {
    font-size: 12px;
  }
}
