
/* Comment container styling */
.comment-container {
    max-width: 88%;
    margin: 40px auto;
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

/* Individual comment styling */
.comment {
    border-bottom: 1px solid #e0e0e0;
    padding: 20px 0;
}

.comment:last-child {
    border-bottom: none;
}

/* Comment header styling */
.comment-header {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.profile-pic {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 15px;
}

.comment-info {
    flex-grow: 1;
}

.comment-name {
    font-size: 16px;
    font-weight: bold;
    margin: 0;
}

.rating {
    display: inline-block;
    /* color: #4d90fe; */
    margin-right: 10px;
}

.comment-time {
    font-size: 14px;
    color: #999;
}

/* Comment text styling */
.comment-text {
    font-size: 14px;
    color: #555;
    margin: 0;
}

/* Button styling */
.view-all {
    display: block;
    width: 100%;
    padding: 10px;
    background-color: #f1f1f1;
    border: 1px solid #ddd;
    border-radius: 5px;
    text-align: center;
    color: #4d90fe;
    font-size: 16px;
    cursor: pointer;
    margin-top: 20px;
    transition: background-color 0.3s ease;
}

.view-all:hover {
    background-color: #e0e0e0;
}

.vendor-view-more1 {
    text-align: center;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  
  .vendor-view-more1 button {
    padding: 10px 20px;
    background-color: white;
    border: 1px solid #a4b0fa;
    color: #a4b0fa;
    border-radius: 20px;
    cursor: pointer;
  }
  
  .vendor-view-more1 button:hover {
    background-color: #a4b0fa;
    color: white;
  }
  @media (max-width:480px) 
  {
    .comment-container {
        max-width: 97%;
       
    }
  }