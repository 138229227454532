.w-100{
    width: 100%;
}
.w-50{
    width: 50%;
}
.backgroundgray{
    background-color: #f4f4f4;
    padding-top: 40px;
    padding-bottom: 10px;
}
.vendor-button {
    border: 2px solid  #91AEEE
    ;
    border-radius: 2rem;
    padding: 3px;
  }
  .vendor-send-message {
    padding: 6px 20px;
    gap: 10px;
    border-radius: 50px;
    border: none;
    background-color: #91AEEE;
    color: white;
    font-size: 20px;
  }
  
  .login {
    padding: 6px 20px;
    gap: 10px;
    border-radius: 50px;
    border: none;
    background-color: white;
    color: #91aeee;
    font-size: 20px;
  }
  .hr{
    margin-top: 5px;
    margin-bottom: 5px;
  }

.vendor-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin:  auto;
    max-width: 1200px;
    background-color: #f4f4f4;
}

/* Profile Card - 70% width */
.vendor-profile-card {
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 55%;
    /* padding: 20px; */
    display: flex;
    flex-direction: column;
}

.vendor-profile-image {
    width: 100%;
    border-radius: 10px;
}

.vendor-profile-details {
    text-align: left;
    margin-top: 15px;
    padding: 20px;
}

.vendor-profile-details h2 {
    color: #4c9ce7;
    font-size: 2em;
}

.vendor-location {
    color: #777;
    font-size: 1em;
    margin-top: 10px;
}

.vendor-reviews {
    color: #555;
    margin: 10px 0;
    font-size: 1.1em;
}

.vendor-actions {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

/* Specific action buttons */
.vendor-action-button {
    background-color: #e9f0fb;
    border: none;
    padding: 10px;
    font-size: 1em;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s;
    display: flex;
    align-items: center;
}

.vendor-action-button i {
    margin-right: 5px;
    font-size: 1.2em;
}

.vendor-action-button:hover {
    background-color: #d1e3f7;
}

/* Contact Card - 30% width */
.vendor-contact-card {
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    width: 43%;
}

.vendor-price-info {
    text-align: left;
    margin-bottom: 20px;
}

.vendor-price-info p {
    font-size: 1em;
    color: #777;
}

.vendor-price-info h3 {
    font-size: 1.3em;
    color: #333;
}

.vendor-contact-form {
    display: flex;
    flex-direction: column;
}

/* Specific buttons for the contact form */
.vendor-send-message-button {
    background-color: #4c9ce7;
    color: white;
    padding: 10px;
    margin: 10px 0;
    border: none;
    border-radius: 5px;
    font-size: 1em;
    cursor: pointer;
}

.vendor-view-contact-button {
    background-color: #ddd;
    padding: 10px;
    margin: 10px 0;
    border: none;
    border-radius: 5px;
    font-size: 1em;
    cursor: pointer;
}

form input,
form textarea {
    margin-top: 10px;
    padding: 10px;
    font-size: 1em;
    border: 1px solid #ddd;
    border-radius: 5px;
}

form textarea {
    resize: none;
}

.vendor-send-form-button {
    background-color: #4c9ce7;
    color: white;
    padding: 10px;
    margin-top: 15px;
    border: none;
    border-radius: 5px;
    font-size: 1em;
    cursor: pointer;
}

/* Responsive design */
@media screen and (max-width: 768px) {
    .vendor-container {
        flex-direction: column;
        align-items: center;
    }

    .vendor-profile-card,
    .vendor-contact-card {
        width: 90%;
        margin-bottom: 20px;
    }

    .vendor-profile-details h2 {
        font-size: 1.6em;
    }

    .vendor-price-info h3 {
        font-size: 1.1em;
    }

    .vendor-action-button,
    .vendor-send-message-button,
    .vendor-view-contact-button,
    .vendor-send-form-button {
        font-size: 0.9em;
    }

    form input, form textarea {
        font-size: 0.9em;
    }
}

@media screen and (max-width: 480px) {
    .vendor-container {
        flex-direction: column;
        align-items: center;
        margin: 20px auto;
    }

    .vendor-profile-card, .vendor-contact-card {
        width: 100%;
        margin-bottom: 20px;
        padding: 15px;
    }

    .vendor-profile-details h2 {
        font-size: 1.4em;
    }

    .vendor-location,
    .vendor-reviews,
    .vendor-price-info p,
    .vendor-price-info h3 {
        font-size: 0.9em;
    }

    .vendor-action-button,
    .vendor-send-message-button,
    .vendor-view-contact-button,
    .vendor-send-form-button {
        font-size: 0.85em;
    }

    form input, form textarea {
        font-size: 0.85em;
        padding: 8px;
    }

    .vendor-send-form-button {
        margin-top: 10px;
    }
    .backgroundgray{
        padding-top: 0px;
    }
    
.vendor-container1 {
    width: 100% !important;
    margin: 0 !important;
  }
}



.vendor-container1 {
   margin-top: 13px;
  }


.vendor-tabs {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 20px;
  }
  
  .vendor-tab {
    padding: 10px 20px;
    background-color: #f0f0f0;
    border: none;
    cursor: pointer;
    font-size: 16px;
  }
  
  .vendor-tab.vendor-active {
    background-color: #a4b0fa;
    color: white;
  }
  
  .vendor-portfolio-nav {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .vendor-portfolio-btn {
    padding: 10px 15px;
    background-color: #f0f0f0;
    border: none;
    cursor: pointer;
    font-size: 16px;
  }
  
  .vendor-portfolio-btn.vendor-active {
    border-bottom: 2px solid #a4b0fa;
    color: #a4b0fa;
    background-color: white;
  }
  
  .vendor-portfolio-gallery {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    justify-content: center;
  }
  
  .vendor-image-container {
    width: 30%;
  }
  
  .vendor-image-container img {
    width: 100%;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .vendor-view-more {
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  
  .vendor-view-more button {
    padding: 10px 20px;
    background-color: white;
    border: 1px solid #a4b0fa;
    color: #a4b0fa;
    border-radius: 20px;
    cursor: pointer;
  }
  
  .vendor-view-more button:hover {
    background-color: #a4b0fa;
    color: white;
  }
  
  .vendor-hidden {
    display: none;
  }
  @media screen and (max-width: 480px) {
    /* Ensure buttons are displayed in two rows on mobile view */
    .vendor-actions {
        flex-wrap: wrap;
    }

    .vendor-action-button {
        width: 50%;
        margin-bottom: 10px; /* Adds spacing between rows */
    
    }
    .vendor-profile-details {
       
        padding: 0;
    }
}
