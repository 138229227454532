.location{
    font-size: 14px;
    margin-bottom: 7px;
}
.tender-heading {
    text-align: left;
    margin-bottom: 20px;
    color: #333;
}
.tendor-button{
    justify-content: space-between;
    margin-top: 5px;
}
.tender-container{
    width: 80%;
    margin-top: 20px;
    margin-bottom: 40px;
}
.tender-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* 4 columns */
    gap: 20px;
    
}

.tender-card {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: box-shadow 0.3s ease-in-out;
   
}

.tender-card:hover {
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.tender-card img {
    width: 100%;
    height: 150px;
    object-fit: cover;
}

.tender-info {
    padding: 10px;
}

.tender-info h2 {
    font-size: 18px;
    margin-bottom: 10px;
}

.tender-info p {
    font-size: 14px;
    color: #666;
}

.tender-status {
    display: inline-block;
    padding: 5px 10px;
    border-radius: 15px;
    font-size: 12px;
    margin-right: 10px;
    color: #fff;
}

.tender-running {
    background-color: #ffcc80;
}

.tender-open {
    background-color: #81c784;
}

.tender-closed {
    background-color: #e57373;
}

.tender-view-details {
    font-size: 12px;
    color: #2196F3;
    text-decoration: none;
    border: 1px solid #2196F3;
    padding: 5px 10px;
    border-radius: 15px;
    display: inline-block;
    transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
}

.tender-view-details:hover {
    background-color: #2196F3;
    color: #fff;
}

/* Responsive Design */
@media (max-width: 1200px) {
    .tender-grid {
        grid-template-columns: repeat(3, 1fr); /* 3 columns for medium screens */
    }
}

@media (max-width: 900px) {
    .tender-grid {
        grid-template-columns: repeat(2, 1fr); /* 2 columns for smaller screens */
    }
}

@media (max-width: 600px) {
    .tender-grid {
        grid-template-columns: 1fr; /* 1 column for mobile screens */
    }
}
