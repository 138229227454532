.review-container {
  width: 90%;
  max-width: 1200px;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-wrap: wrap;
}

.rating-summary, 
.submit-review {
  width: 50%;
  padding: 10px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .rating-summary, 
  .submit-review {
    width: 100%;
  }
}

.average-rating h1 {
  font-size: 64px;
  margin-bottom: -15px;
}

.stars span {
  color: #FFD700;
  font-size: 24px;
}

.average-rating p {
  font-size: 14px;
  color: #666;
  margin-bottom: 1rem;
}

.rating-breakdown .rating-bar {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.rating-breakdown .rating-bar span {
  font-size: 14px;
  margin-right: 10px;
}

.rating-breakdown .bar {
  background-color: #e0e0e0;
  width: 70%;
  height: 8px;
  border-radius: 4px;
  margin-right: 10px;
  position: relative;
}

.rating-breakdown .fill {
  height: 100%;
  background-color: #4A90E2;
  border-radius: 4px;
}

/* Submit Review Section Styling */
.submit-review h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

.stars-input span {
  font-size: 30px;
  color: #ccc;
}

/* Container for the form and photo upload */
.submit-review-content {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

/* Left side - 70% width */
.review-form {
  width: 100%;
}

/* Right side - 30% width */
.review-photo-upload {
  width: 30%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.upload-photo {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.upload-photo label {
    background-color: #eee;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    text-align: center;
    height: 6rem;
    align-items: center;
    display: flex;
}

/* Review form input styling */
form input, form textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 14px;
}

form textarea {
  resize: none;
  height: 80px;
}

/* Submit Button */
.reviews-button {
  background-color: #4A90E2;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.reviews-button:hover {
  background-color: #357ABD;
}

/* Responsive design for smaller screens */
@media (max-width: 768px) {
  .submit-review-content {
    flex-direction: column;
  }

  .review-form, .review-photo-upload {
    width: 100%;
  }

  .review-photo-upload {
    align-items: flex-start;
  }
}
@media (max-width: 480px) {
 .review-container{
  width: 97%;
 }
}
