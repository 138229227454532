/* Filter Bar */
.filter-bar {
    margin: 20px auto;
    text-align: center;
  }
  
  .filter-dropdown {
    width: 100%;
    max-width: 300px;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  /* Container for Vendor Cards */
  .container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(352px, 1fr));
    gap: 20px;
    padding: 20px 80px;
  }
  
  .card-home {
    position: relative;
    border: 1px solid #ddd;
    border-radius: 10px;
    overflow: hidden;
    background-color: #fff;
    transition: transform 0.2s;
  }
  
  .card-home:hover {
    transform: scale(1.02);
  }
  
  .card-home img {
    width: 100%;
    height: 150px;
    object-fit: cover;
  }
  
  /* .shadow {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.5));
  } */
  
  .info-overlay {
    position: absolute;
    top: 10px;
    right: 10px;
    color: white;
    font-size: 14px;
    font-weight: bold;
  }
  
  .card-body {
    padding: 15px;
  }
  
  .vendor-name {
    font-size: 16px;
    font-weight: bold;
  }
  
  .card-location {
    font-size: 14px;
    color: #777;
  }
  
  .card-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
  }
  
  .starting-price {
    font-size: 14px;
    font-weight: bold;
  }
  
  .card-button {
    padding: 5px 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  /* Responsive Design */
  @media screen and (max-width: 768px) {
    .container {
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    }
  
    .filter-dropdown {
      width: 90%;
      margin: 10px auto;
    }
  }
  
  @media screen and (max-width: 480px) {
    .container {
      grid-template-columns: 1fr;
    }
  
    .filter-dropdown {
      width: 100%;
    }
  
    .card-home img {
      height: 120px;
    }
  }
  /* Filter Wrapper */
.filter-bar-wrapper {
    margin: 20px auto;
    text-align: center;
    position: relative;
  }
  
  /* Filter Bar for Larger Screens */
  .filter-bar {
    display: flex;
    justify-content: center;
    gap: 10px;
  }
  
  .filter-bar button {
    padding: 10px 15px;
    border: 1px solid #ccc;
    background-color: #f9f9f9;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .filter-bar button.active {
    background-color: #007bff;
    color: white;
    border-color: #007bff;
  }
  
  /* Filter Dropdown for Small Screens */
  .filter-dropdown {
    display: none;
    width: 100%;
    max-width: 300px;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-top: 10px;
  }
  
  /* Responsive Design */
  @media screen and (max-width: 768px) {
    /* Hide bar and show dropdown */
    .filter-bar {
      display: none;
    }
  
    .filter-dropdown {
      display: block;
    }
  
    .container {
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    }
  }
  
  @media screen and (max-width: 480px) {
    .container {
      grid-template-columns: 1fr;
    }
  
    .filter-dropdown {
      width: 100%;
    }
  
    .card-home img {
      height: 120px;
    }
  }
  
  .image-container {
    position: relative;
    width: 100%;
    height: auto;
  }
  
  .tag {
    position: absolute;
    top: 10px;
    left: 10px;
    color: white;
    font-size: 12px;
    font-weight: bold;
    padding: 5px 10px;
    border-radius: 5px;
    z-index: 10;
    text-transform: capitalize;
  }
  