/* Consolidated Font Import */
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Caveat:wght@400;700&family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Cinzel:wght@400;900&family=Dancing+Script:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400..700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Caveat:wght@400..700&display=swap');

.trybutton{
    border: none;
    font-size: 20px;
    background-color: white;
    cursor: pointer;
}
/* .highlight{
    color: black !important;
} */
 .boald{
    font-weight: bold;
 }

/* Main Styles */
.hero_section {
    margin: 0;
    width: 100vw;
    height: 100vh;
    /* padding: 0 10%;     ------------------comment line this pages  */
    overflow-x: hidden;
    /* margin-top: 10vh; */
    /* background: linear-gradient(to top, #5f72bd 0%, #9b23ea 100%); */
}

.hero_comp {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 95%;
    width: 100%;
}

.hero_content {
    padding: 2%;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}

.hero_content_heading .full_svg_home {
    width: 1200px;
}

.hero_content_heading .mobile_svg_home {
    display: none;
}

.hero_content_tagline {
    width: 70%;
    font-size: 24px;
    font-family: 'Roboto', sans-serif;
    text-align: center;
    color: #708090;
}

.hero_demo {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.hero_demo .hero_demo_text p {
    font-family: 'Poppins', sans-serif;
    margin-right: 10px;
    color: gray;
    font-size: 18px;
    margin-bottom: 8px;
}

.hero_demo_text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.hero_cta {
    padding: 10px 4px;
    background-color: #562D7B;
    display: flex;
    justify-content: center;
    font-size: 16px;
    align-items: center;
    font-family: 'Roboto', sans-serif;
    color: white;
    width: 110%;
    font-weight: 500;
    border: 0;
    border-radius: 6px;
    letter-spacing: 0.5px;
    cursor: pointer;
}

.arrow_hero {
    position: absolute;
    bottom: 20px;
    right: 100px;
    zoom: 2;
}

.hero_demo .hero_demo_line {
    width: 80%;
    height: 4px;
    border-radius: 10px;
    background-color: rgb(103, 15, 141);
}

.hero_graphic img {
    width: 550px;
}

/* Responsive Styles */
@media screen and (max-width: 1200px) {
    .hero_content_heading .full_svg_home {
        width: 800px;
    }
    .hero_content_tagline {
        width: 90%;
        font-size: 24px;
    }
    .hero_demo {
        width: 70%;
    }
    .hero_cta {
        width: 50%;
    }
}

@media screen and (max-width: 920px) {
    .hero_content_heading .mobile_svg_home {
        margin-bottom: 20px;
        display: block;
        width: 40px;
    }
    .hero_content_heading .full_svg_home {
        display: none;
    }
    .hero_content_tagline {
        width: 90%;
        font-size: 20px;
    }
    .hero_demo {
        width: 90%;
        margin-bottom: 20px;
    }
    .hero_demo_text {
        font-size: 12px;
    }
    .hero_cta {
        font-size: 16px;
        width: 100%;
    }
}

@media screen and (max-width: 480px) {
    .hero_section {
        height: 50vh;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .hero_comp {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .arrow_hero {
        display: none;
    }
    .hero_content_heading .mobile_svg_home {
        margin-bottom: 20px;
        margin-top: 140px;
        display: block;
        width: 320px;
    }
    .hero_content_heading .full_svg_home {
        display: none;
    }
    .hero_content_tagline {
        width: 100%;
        font-size: 15px;
    }
    .hero_demo {
        width: 90%;
        margin-bottom: 100px;
    }
    .hero_demo_text {
        font-size: 12px;
    }
    .hero_cta {
        font-size: 12px;
        width: 100%;
    }
}


/* extra css  */
.roboto_regular_italic {
    /* font-family: "Roboto", sans-serif; */
    /* font-family: 'Caveat', cursive; */
    font-weight: 700;
    font-style: italic;
    /* font-size: 5rem; */
    font-size: 3rem;
  }
  .colorlogo{
    color: #562D7B;
  }
  @media screen and (max-width:500px){
    .roboto_regular_italic{
        font-size: 2rem;
       margin-bottom: 2rem;
        justify-content: center;
        align-items: center;
    } 
    .hero_content {
        padding: 2%;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: end;
        align-items: center;
        position: relative;
        margin-top: 12rem;
    }
  }


  .icic {
    margin-bottom: 2rem;
    display: flex;
    align-items: center;
    justify-content: center; 
    gap: 1rem; /* Add spacing between the image and text */
}

.icic img {
    width: 300px; /* Default image width for larger screens */
}

.hero_content_heading {
    width: auto;
    text-align: center;
}

@media screen and (max-width: 500px) {
    .icic {
        display: flex;
        flex-direction: column; /* Stack content vertically */
        align-items: center; /* Center items in the vertical stack */
    }

    .icic img {
        width: 90%; /* Image width set to 90% of the container */
    }

    
}
