/* Base Styles */
.bid-container {
  display: flex;
  gap: 20px;
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  flex-wrap: wrap;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.bid-details-section,
.bid-bidding-section {
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.bid-details-section {
  flex: 1 1 60%;
  min-width: 300px;
}

.bid-details-section h2 {
  margin-bottom: 20px;
  font-size: 20px;
  color: #333;
}

.bid-detail-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  font-size: 16px;
  color: #777;
}

.bid-description p {
  margin-top: 10px;
  color: #777;
  font-size: 14px;
}

.bid-bidding-section {
  flex: 1 1 35%;
  min-width: 300px;
}

.bid-bidding-section h3 {
  text-align: center;
  font-size: 18px;
  color: #333;
  margin-bottom: 20px;
}

.bid-item-container {
  display: flex;
  flex-direction: column;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 15px;
  background-color: #f9f9f9;
}

.bid-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
}

.bid-user-info {
  display: flex;
  align-items: center;
  gap: 10px;
}

.bid-user-avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 2px solid #ddd;
}

.bid-name {
  font-weight: bold;
  font-size: 16px;
  color: #333;
}

.bid-details {
  font-size: 14px;
  color: #555;
}

.bid-amount,
.bid-date,
.negotiation-price {
  margin: 5px 0;
}

.negotiation-price {
  color: #007bff;
  font-weight: bold;
}

.bid-actions {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.bid-button {
  padding: 8px 12px;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  flex: 1;
}

.negotiate-button {
  background-color: #007bff;
  color: #fff;
}

.final-vendor-button {
  background-color: #28a745;
  color: #fff;
}

.negotiation-input-container {
  margin-top: 10px;
  padding: 15px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.negotiation-input-group {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.negotiation-input {
  flex: 1;
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.submit-negotiation-button {
  padding: 8px 12px;
  background-color: #28a745;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.cancel-negotiation-button {
  padding: 8px 12px;
  background-color: #dc3545;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

/* Responsive Styles */
@media (max-width: 1024px) {
  .bid-container {
    flex-direction: column;
  }

  .bid-details-section,
  .bid-bidding-section {
    width: 100%;
    margin-bottom: 20px;
  }
}

@media (max-width: 768px) {
  .bid-item {
    flex-direction: column;
    align-items: flex-start;
  }

  .bid-actions {
    flex-direction: column;
    gap: 5px;
  }

  .negotiation-input-group {
    flex-direction: column;
  }
}

@media (max-width: 480px) {
  .bid-user-avatar {
    width: 40px;
    height: 40px;
  }

  .bid-name {
    font-size: 14px;
  }

  .bid-details {
    font-size: 12px;
  }

  .bid-amount {
    font-size: 12px;
  }

  .submit-negotiation-button,
  .cancel-negotiation-button {
    font-size: 12px;
    padding: 6px 10px;
  }
}
.finalized-vendor {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 10px;
  margin-top: 20px;
}

.finalized-vendor-card {
  display: flex;
  align-items: center;
  gap: 20px;
}

.vendor-avatar {
  flex-shrink: 0;
}

.vendor-avatar-img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  border: 2px solid #4caf50;
}

.vendor-details {
  flex-grow: 1;
  font-size: 16px;
}

.vendor-details p {
  margin: 5px 0;
}

h3 {
  margin-bottom: 15px;
  color: #4caf50;
}
.rating-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.rating-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  width:400px;
  /* max-width: fit-content; */
  z-index: 1001;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.rating-input-group,
.feedback-input-group {
  margin-bottom: 15px;
}

.rating-modal-actions {
  display: flex;
  justify-content: space-between;
}

.submit-feedback-button,
.cancel-feedback-button {
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

.submit-feedback-button {
  background: #28a745;
  color: #fff;
}

.cancel-feedback-button {
  background: #dc3545;
  color: #fff;
}
.close-tender-button{
  background: #28a745;
  color: #fff;
  padding: 6px ;
  border: 2px solid white;
  border-radius: 4px;
  margin: 10px 0 ;
}
.feedback-input-group{
  flex-direction: column;
  display: flex;
  gap: 5px;
  
}
.feedback-input-group textarea{
  padding: 5px;
  margin: 2px 0;
}
.feedback-input-group label{
  /* padding: 5px; */
  margin: 2px 0;
}
