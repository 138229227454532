.top-menu {
    background-color: #91AEEE;
    display: flex;
    width: 100%;
    justify-content: space-around;
    align-items: center;
}
.top-menu-left ul li{
    list-style: none;
    border-left: 1px solid #ffffff;
    align-items: center;
    padding-block: 10px;    
}
.top-menu-left ul li:last-child{
    border-right:1px solid #ffffff;
}
.top-menu-left ul{
    display: flex;

}
.top-menu ul li{
    list-style: none;
}
.top-menu-left ul li a i{
    font-size: 15px;
    padding-inline: 10px;
}
.top-menu-right ul {
    display: flex;
}
.top-menu-right ul li a {
    text-decoration: none;
    color: white;
    gap: 10px;
    display: flex;
}
.fa-brands{
    color: white;
}
.width42{
    width: 42%;
}
.fix{
    display: flex;
    align-items: center;
}
.marginx{
    margin-right: 1rem;
}
@media (max-width: 990px) {
    .top-menu {
      display: none;
    }
}