.event-gap{
    gap: 45px;
    font-size: 1.3rem;
}
@media (max-width:520px) {
    .eventorg{
        display: none;
    }
    .eventorgdown{
        margin-bottom: 10px;
    }
    
}
@media (min-width:521px) {
    .eventorgdown{
        display: none;
    }
    
}
/* Filter Tabs */
.filter-tabs {
    display: flex;
    justify-content: center;
    gap: 15px;
    margin-bottom: 20px;
  }
  
  .filter-tab {
    padding: 10px 20px;
    background-color: #f0f0f0;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .filter-tab.active {
    background-color: #007bff;
    color: #fff;
  }
  
  .filter-tab:hover {
    background-color: #007bff;
    color: #fff;
  }
  
  /* Tenders Grid */
  .tender-grid {
   display: flex;
   justify-content: start;
   align-items: center;
   flex-wrap: wrap;
  }
  
  .tender-card {
    width: 250px;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 15px;
    background-color: #fff;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .tender-card img {
    width: 100%;
    border-radius: 8px;
  }
  
  .tender-info {
    margin-top: 10px;
  }
  
  .tender-status-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
  }
  
  .tender-status {
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 14px;
    font-weight: bold;
    text-transform: capitalize;
  }
  
  .tender-status.open {
    background-color: #28a745;
    color: #fff;
  }
  
  .tender-status.closed {
    background-color: #dc3545;
    color: #fff;
  }
  
  .tender-status.awarded {
    background-color: #ffc107;
    color: #000;
  }
  
  .view-details-link {
    color: #007bff;
    text-decoration: none;
    font-weight: bold;
  }
  
  .view-details-link:hover {
    text-decoration: underline;
  }
  /* Filter Dropdown */
.filter-dropdown {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    font-size: 16px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #fff;
  }
  
  /* Media Queries for Responsive Design */
  @media (max-width: 768px) {
    .filter-tabs {
      display: none; /* Hide filter tabs on small screens */
    }
  
    .tender-grid {
      justify-content: center; /* Center cards on small screens */
    }
  }
  
  
  